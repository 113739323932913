.ffPagination {
  width: 100%;
  padding-top: 14px;
  @media (max-width:991px) {
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 10px;
  }
  ul {
    display: flex;
    align-items: center;
    align-content: center;
    gap: 2px;
    li {
      a {
        color: #808080;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        white-space: nowrap;
        display: flex;
        width: 40px;
        height: 40px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0; 
        &.selected {
          color: #000;
        }
        &.ffPrev {
          border-radius: 100px;
          border: 1px solid #444;
        }
        &.ffNext {
          border-radius: 100px;
          border: 1px solid #444;
        }
      }
    }
  }
}

.ffMainContent {
  &.darkTheme {
    .ffPagination {
      ul {
        li {
          a {
            color: #B2B2B2;
            &.selected {
              color: #FFF;
            }
            &.ffPrev {
              border: 1px solid #B2B2B2;
              svg {
                stroke: #B2B2B2;
              }
            }
            &.ffNext {
              border: 1px solid #B2B2B2;
              svg {
                stroke: #B2B2B2;
              }
            }
          }
        }
      }
    }
  }
}